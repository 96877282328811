export const getGuaranteeEnabledStores = (stores) => {
  if (Array.isArray(stores)) {
    const filtered = stores.reduce((acc, { store }) => {
      if (
        store?.enable_consumer_guaranteed_price ||
        store?.enable_dealer_guaranteed_price
      ) {
        acc.push(store.id);
      }
      return acc;
    }, []);
    return filtered?.length > 0 ? filtered : null;
  }

  return null;
};
