export const getValue = (obj, paths) => {
  try {
    if (paths.length > 1) {
      const removed = paths.shift();
      return getValue(obj[removed], paths);
    } else {
      if (paths[0] === 'vehicle') {
        return `${obj['year']} ${obj['make']} ${obj['model']}  VIN: ${obj['vin']}`;
      } else {
        return obj[paths[0]];
      }
    }
  } catch {
    return null;
  }
};

export const parsePathDashed = (objectToParse, pathDash) => {
  return getValue(objectToParse, pathDash.split('__'));
};
