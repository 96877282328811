import { Box, Text } from '@drivably-apps/component-lib';
import { Icon } from '@portal/ui';

const LearnMore = () => {
  return (
    <Box
      isFlex
      align="center"
      justify="space-around"
      radius="md"
      paddingHorizontal="xs"
      color="gray"
    >
      <Icon icon="InfoOutlinedIcon" size="md2" />
      <Text size="xs" weight="bold" color="gray-text" padding="xs">
        Learn more
      </Text>
    </Box>
  );
};
export default LearnMore;
